$bg-about: #141513;
$brown: #504263;
$black: #121c0e;
$black-000: #000000;
//============
$green-50: #e6f3e1;
$green-100: #4ca027;
$green-200: #8fd077;
//============
$blue-50: #d1eefd;
$blue-100: #00c2ff;
$blue-200: #1a89f0;
//============
$purple-50: #f0eaff;
$purple-100: #9747ff;
$purple-200: #7747ff;
$purple-300: #6541ba;
$yellow-200: #ffb800;
$yellow-50: #fffae6;
$white-000: #fff;
$white-100: #f7f3ff;
$grey-10: #f5f5f5;
$grey-20: #b2b2b2;
$grey-100: #5d6859;
